:root {
  --color-main: #fa755d;
}

* {
  box-sizing: border-box;
  word-break: break-word;
}

html {
  height: 100vh;
  font-size: 62.5%;
  background-color: var(--background-color);
  overflow-x: hidden;
}

body {
  font-size: 1.7rem;
  font-family: 'Poppins-Light';
  width: 100vw;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
