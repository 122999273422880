.error-screen-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  width: 100%;
  height: 100%;
  padding: 10px;
  min-width: 250px;
}
.error-screen-title{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;

  font-family: "Poppins-Medium";
  font-size: 4.5rem;
  margin: 0;
  color: var(--primary-font-color);
}
.error-screen-icon{
  font-size: 10rem;
  color: var(--primary-color);
}
.error-screen-text{
  font-weight: bold;
  font-style: oblique;
  text-align: center;
  color: var(--primary-font-color);
}
.error-screen-reload-btn{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  justify-items: center;
  gap: 5px;

  cursor: pointer;
  border-radius: 10px;
  border: var(--primary-color) solid 1px;
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 10px;
  font-family: "Poppins-Medium";
  transition: all 0.1s ease-in-out;
}
.error-screen-reload-btn:hover{
  background-color: var(--background-color);
  color: var(--primary-color);
}
.error-screen-reload-icon{
  font-size: 2rem;
}

.error-screen-minified{
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 10px;
}
.error-screen-minified .error-icon{
  font-size: 30px;
  color: var(--primary-color);
}