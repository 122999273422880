.modal-container{
  grid-template-columns: min-content;
  grid-template-rows: min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
}
.modal-true{
  display: grid;
}
.modal-false{
  display: none;
}
.modal-background{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.377);
  width: 100%;
  height: 100%;
  z-index: 601;
}
.modal-content{
  background-color: var(--background-color);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.575);
  border-radius: 5px;
  z-index: 602;
  width: min-content;
  max-width: 100vw;
  max-height: 90vh;
  overflow: auto;
}
.modal-title{
  margin: 0;
  font-weight: bold;
  justify-self: center;
  font-style: oblique;
  font-family: "Poppins-Medium";
  text-align: center;
  color: var(--primary-font-color);
}
.modal-description{
  margin: 0;
  font-weight: lighter;
  justify-self: center;
  font-style: oblique;
  text-align: center;
  font-family: "Poppins-Light";
  color: var(--primary-font-color);
}
.modal-body{
  display: grid;
  gap: 20px;

  width: 600px;
  padding: 20px;
}
.modal-actions{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 610px) {
  .modal-body{
    width: 280px;
    padding: 10px;
  }
  .modal-actions{
    gap: 10px;
  }
}