#init-container{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  grid-template-rows: minmax(65px, 80px) repeat(11, 1fr);

  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}

.root-content{
  width: 100vw;
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  padding: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111b2600;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--secondary-border-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}

/* ALL */
/* FLEX GRID */
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-items-center {
  justify-items: center;
}

.align-content-center {
  align-content: center;
}

.align-items-center {
  align-items: center;
}

.col-md-7 {
  width: 58.333%;
}

.col-md-5 {
  width: 41.667%;
}
/* FLEX GRID */
/* BORDER */
.b-radius-1 {
  border-radius: 5px;
}

.b-1 {
  border: 2px dashed var(--primary-border-color);
}

.bt-1 {
  border-top: 2px dashed var(--primary-border-color);
}

.br-1 {
  border-right: 2px dashed var(--primary-border-color);
}

.bb-1 {
  border-bottom: 2px dashed var(--primary-border-color);
}

.bl-1 {
  border-left: 2px dashed var(--primary-border-color);
}
/* BORDER */
/* PADDING */
.p-1 {
  padding: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.p-3 {
  padding: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}
/* PADDING */
/* MARGIN */
.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mx-1 {
  margin: 0rem 1rem;
}

.my-1 {
  margin: 1rem 0rem;
}

.mx-3 {
  margin: 0rem 3rem;
}

.my-3 {
  margin: 3rem 0rem;
}
/* MARGIN */
.t-center {
  text-align: center;
}

.pointer:hover {
  cursor: pointer;
}
/* TABLE */
.table .MuiDataGrid-columnHeadersInner {
  font-family: 'Poppins-Medium';
  font-size: 1.6rem;
  font-weight: bold;
}

.table .MuiDataGrid-virtualScrollerRenderZone {
  font-family: 'Poppins-Regular';
  font-size: 1.3rem;
}

.table .MuiTablePagination-displayedRows {
  font-family: 'Poppins-Regular' !important;
  font-size: 1.3rem !important;
}
/* TABLE */
/* AUTOCOMPLETE */

.MuiAutocomplete-paper {
  font-family: 'Poppins-Regular' !important;
  font-size: 1.3rem !important;
}
/* AUTOCOMPLETE */
/* ALL */
