.alert-container{
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;

  position: fixed;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  min-width: 280px;
  max-width: 700px;
  border-radius: 5px;
  color: #fff;
  transition: 0.2s ease-in-out;
  padding: 10px;
  border: 2px solid #fff;
  bottom: -100vh;
  z-index: 700;
}
.alert-display-true{
  bottom: 1vh
}
.alert-icon{
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 3rem;
  margin-right: 10px;
}
.alert-title{
  text-align: center;
  font-family: "Poppins-Bold";
  font-size: 2rem;
  word-break: break-word;
}
.alert-message{
  text-align: center;
  word-break: break-word;
}
.alert-success{
  background-color: rgb(38, 192, 0);
}
.alert-danger{
  background-color: rgb(218, 0, 0);
}
.alert-warning{
  background-color: rgb(201, 187, 0);
}
.alert-close-icon{
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}