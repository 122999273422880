#my-sidebar{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(65px, 80px) 1fr;

  position: absolute;
  height: 100vh;
  width: 280px;
  background-color: var(--background-color);
  border-right: 2px solid var(--secondary-border-color
  );
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
}

.sidebar-deploy-true{
  left: 0px;
}
.sidebar-deploy-false{
  left: -280px;
}

.sidebar-head{
  display: grid;
  grid-template-columns: minmax(65px, 80px) 1fr;
  grid-template-rows: 100%;
  align-items: center;
  justify-items: center;
  border-bottom: 2px solid var(--secondary-border-color);
  height: 100%;
}
.sidebar-head > img{
  height: 80%;
  justify-self: start;
  object-fit: contain;
  object-position: center;
}
.sidebar-btn{
  cursor: pointer;
  border: none;
  background-color: var(--background-color);
  color: var(--primary-font-color);
  font-size: 3rem;
  width: 90%;
  height: 90%;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
}
.sidebar-btn:hover{
  background-color: var(--background-color-contrasted);
}
.sidebar-logo-ibisa-container{
  display: grid;
  grid-template-rows: 100%;
  align-items: center;
  justify-self: start;
  height: 100%;
}
.sidebar-logo-ibisa-container img{
  object-fit: cover;
  height: 60%;
}

.sidebar-body{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;

  margin-top: 10px;
  gap: 40px;
  width: 100%;
  height: calc(100% - 10px);
  overflow: auto;
}

.sidebar-tools{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  transition: 0.2s ease-in-out;
  gap: 10px;
}
.sidebar-tools a{
  text-decoration: none;
}
.sidebar-tool{
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-items: center;
  
  padding: 10px;
  width: 100%;
  color: var(--secondary-font-color);
  transition: 0.1s ease-in-out;
}
.sidebar-tool:hover{
  background-color: var(--background-color-contrasted);
  cursor: pointer;
}
.sidebar-tool--selected{
  color: white;
  background-color: #fa755d;
}
.sidebar-tool--selected:hover{
  background-color: #ff8974;
}
.sidebar-tool-icon{
  font-size: 2rem;
}
.sidebar-tool-text{
  font-weight: bold;
  justify-self: start;
  margin-left: 20px;
}

#my-sidebar .apps-sidebar-option{
  display: none;
  color: var(--secondary-font-color);
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
}

.apps-deploy-button{
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  align-items: center;
  justify-items: center;
  padding: 10px;
  cursor: pointer;
}
.apps-deploy-button:hover{
  background-color: var(--background-color-contrasted);
}
.apps-icon-left{
  font-size: 2rem;
}
.apps-text{
  justify-self: start;
  font-weight: bold;
  margin-left: 20px;
}
.apps-icon-right{
  justify-self: end;
}
.apps-options-container{
  display: grid;
  overflow: hidden;
  transition: 0.2s ease-in-out;
}
.apps-sidebar-option-deployed .apps-deploy-button{
  color: #fa755d;
}
.app-sidebar{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  padding: 5px;
  color: inherit;
  text-decoration: none;
}
.app-sidebar-text{
  justify-self: start;
  margin-left: 10px;
}
.app-sidebar:hover{
  background-color: var(--background-color-contrasted);
}
.sidebar-dropdown-deployed .sidebar-tool{
  background-color: var(--background-color-contrasted);
}
.sidebar-dropdown-deployed .sidebar-tool.sidebar-tool--selected{
  color: white;
  background-color: #fa755d;
}
.sidebar-dropdown-deployed .sidebar-tool.sidebar-tool--selected:hover{
  background-color: #ff8974;
}
.sidebar-dropdown-deployed .apps-deploy-button{
  background-color: var(--background-color-contrasted);
}
.sidebar-process-item{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  padding: 5px;
  text-decoration: none;
  color: #3d3d3d;
}
.sidebar-process-item-text{
  justify-self: start;
  margin-left: 10px;
}

/* sidebar dropdown */
.sidebar-dropdown{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
}
.sidebar-dropdown-content{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;

  transition: 0.2s ease-in-out;
  height: 0px;
  overflow: hidden;
  padding-left: 20px;
}
.sidebar-dropdown-deployed .sidebar-dropdown-content{
  height: calc(100% + 0px);
  background-color: var(--background-color-contrasted);
}
.sidebar-dropdown-item{
  color: var(--secondary-font-color);
  padding: 5px;
}
.sidebar-dropdown-item:hover{
  background-color: var(--background-color-contrasted-hover);
}
/* sidebar dropdown */

.sidebar-search-container {
  display: none;
  align-items: center;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;

  height: 60px;
  padding: 0 10px
}

.sidebar-search-container > .sidebar-input-search {
  justify-self: end;
  border: none;
  background-color: var(--background-color-contrasted);
  width: 100%;
  height: 45px;
  border-radius: 10px 0 0 10px;
  padding: 0 0 0 10px;
  color: var(--primary-font-color);
  font-weight: 530;
  font-size: 1.8rem;
  font-family: "Poppins-Light";
}
.sidebar-search-container > .sidebar-input-search:focus {
  border: none;
  outline: none;
}

.sidebar-search-container > .sidebar-input-search::placeholder {
  color: var(--primary-font-color);
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Poppins-Light";
  text-overflow: ellipsis;
}
.sidebar-search-container > .sidebar-input-search-icon {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: var(--background-color-contrasted);
  color: var(--primary-font-color);
  border-radius: 0 10px 10px 0;
  text-align: center;
  height: 45px;
  font-size: 2rem;
}
.sidebar-search-container > .sidebar-input-search-icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 493px) {
  #my-sidebar .apps-sidebar-option{
    display: grid;
  }
}