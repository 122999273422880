.loading-screen-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  place-items: center;
  align-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.loading-screen-bar {
  place-self: center;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loading-screen-bar:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 2px;
  border-radius: 50%;
  border: 6px solid var(--primary-font-color);
  border-color: var(--primary-font-color) transparent var(--primary-font-color) transparent;
  animation: loading-effect 1.2s linear infinite;
}
.loading-screen-message{
  font-style: oblique;
  color: var(--primary-font-color);
}
.loading-screen-minified{
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  place-content: center;
  align-items: center;
  gap: 10px;

  padding: 10px;
}
.loading-icon{
  animation: loading-effect 1.2s linear infinite;
}

@keyframes loading-effect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}