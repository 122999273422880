.loading-alert-container{
  background-color: var(--background-color-contrasted);
  border: var(--primary-border-color) 2px solid;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  position: fixed;
  transition: 0.2s ease-in-out all;
  left: 10px;
  z-index: 700;
}

.loading-alert-display-false{
  bottom: -150px;
}

.loading-alert-display-true{
  bottom: 10px;
}