.notfound-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;

  width: 100%;
  height: 100%;
}

.notfound-error-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  place-items: center;
  
  width: 100%;
  max-width: 600px;
  min-width: 280px;
}

.notfound-error-icon{
  color: var(--primary-color);
  font-size: 10rem;
}

.notfound-error-text{
  font-weight: bold;
  font-style: oblique;
  text-align: center;
  color: var(--primary-font-color);
}