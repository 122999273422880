.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--background-color-contrasted);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 300;
  top: calc(100% + 5px);
  transition: 0.2s ease-in-out;
  opacity: 0;
  border-radius: 7px;
  overflow-x: hidden;
  max-height: calc(100vh - 80px);
  width: max-content;
  max-width: 280px;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
}

.dropdown-content .dropdown-item:hover {
  background-color: var(--background-color-contrasted-hover);
}

.dropdown-deployed .dropdown-content {
  display: block;
  opacity: 1;
}

.dropdown-item{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  justify-items: center;
  row-gap: 10px;

  padding: 12px 16px;
  width: 100%;
}
.dropdown-item-icon{
  font-size: 2rem;
  color: var(--secondary-font-color)
}
.dropdown-item-text{
  font-weight: bold;
  margin-left: 10px;
  justify-self: start;
  color: var(--secondary-font-color)
}
.dropdown-item-content{
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  
  color: var(--primary-font-color);
  width: 100%;
}
.dropdown-click-detector{
  display: grid;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 200;
}