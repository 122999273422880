#my-header {
  display: grid;
  /* grid-template-columns: minmax(35px, 80px) minmax(200px, 480px) 1fr 370px; */
  grid-template-columns: 80px minmax(710px, 1fr) minmax(300px, max-content);
  grid-template-rows: 80px;
  grid-column: 1 / 13;
  grid-row: 1 / 2;

  height: 100%;
  border-bottom: 2px solid var(--secondary-border-color);
  background-color: var(--background-color);
}

#my-header > .logo-ibisa {
  align-self: center;
  justify-self: end;
  height: 60%;
  border-right: 1px solid var(--secondary-border-color);
}

.logo-ibisa img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height : 100%;
}

.btn-sidebar {
  display: none;
  justify-self: center;
  width: 90%;
  height: 90%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  font-size: 3rem;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  color: var(--primary-font-color);
}
.btn-sidebar:hover{
  background-color: var(--background-color-contrasted);
}

.search-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
}

.search-container > .input-search {
  justify-self: end;
  border: none;
  background-color: var(--background-color-contrasted);
  width: 93%;
  height: 57%;
  border-radius: 10px 0 0 10px;
  padding: 0 0 0 10px;
  color: black;
  font-weight: 530;
  font-size: 1.8rem;
  font-family: "Poppins-Light";
}
.search-container > .input-search:focus {
  border: none;
  outline: none;
}

.search-container > .input-search::placeholder {
  color: var(--primary-font-color);
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Poppins-Light";
  text-overflow: ellipsis;
}
.search-container > .input-search-icon {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: var(--background-color-contrasted);
  border-radius: 0 10px 10px 0;
  text-align: center;
  height: 57%;
  font-size: 2rem;
  color: var(--primary-font-color);
}
.search-container > .input-search-icon:hover {
  cursor: pointer;
}

.ibisa-tools-container {
  display: grid;
  grid-template-columns: repeat(5, min-content);
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  gap: 15px;
  justify-content: center;
  height: 100%;

  font-weight: bold;
  margin: 0 10px 0 10px;
}
.ibisa-tools-container > a {
  text-decoration: none;
  color: var(--terciary-font-color);
}
.ibisa-tool {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 0px;
  height: 60%;
  color: var(--terciary-font-color);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.ibisa-tool:hover{
  background-color: var(--background-color-contrasted);
}
.ibisa-tool > .ibisa-tool-icon {
  font-weight: normal;
  justify-self: end;
  font-size: 2rem;
  display: block;
  width: min-content;
  padding: 0 10px 0 10px;
}
.ibisa-tool > .ibisa-tool-text {
  width: max-content;
  padding-right: 10px;
}
.ibisa-tools-container > .ibisa-tool--selected {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.right-ibisa-options {
  display: grid;
  grid-template-columns: max-content min-content minmax(210px, 1fr);
  align-items: center;
  width: 100%;
  /* padding: 10px; */
}

.ibisa-apps-option {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr min-content;
  place-items: center;
  cursor: pointer;
  transition: 0.2 ease-in-out;
  color: var(--secondary-font-color);
  width: 100%;
}
.ibisa-apps-option > .ibisa-apps-icon-left {
  font-size: 2rem;
}
.ibisa-apps-option > .ibisa-apps-text {
  font-weight: bold;
  word-break: break-word;
  margin: 0 5px 0 5px;
  text-overflow: ellipsis;
}
.ibisa-apps-option > .ibisa-apps-icon-right {
  font-size: 1.5rem;
}

.ibisa-apps-option-deployed {
  color: var(--primary-color);
}
.ibisa-apps-option-deployed .ibisa-apps-text {
  color: var(--primary-color);
}

.notification-icon-container {
  display: grid;
  width: min-content;
  cursor: pointer;
  margin: 0 15px 0 15px;
}
.notification-icon-container .dropdown-content{
  width: 280px;
  right: -95px;
}
.notification-icon {
  position: relative;
  font-size: 3.5rem;
  z-index: 1;
  color: var(--primary-font-color);
  width: min-content;
}
.notification-count {
  padding: 0 3px 0 3px;
  position: absolute;
  right: 0;
  border: 4px solid var(--background-color);
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  z-index: 2;
  font-size: 1.3rem;
}
.profile-container {
  display: grid;
  grid-template-columns: minmax(min-content, 150px) 80px;
  grid-template-rows: 1fr;
  height: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.profile-container:hover{
  background-color: var(--background-color-contrasted);
}
.profile-text {
  display: grid;
  align-items: center;
  justify-items: end;
  font-weight: bolder;
}
.profile-name {
  font-family: 'Poppins-ExtraBold';
  display: block;
  overflow: hidden;
  align-self: end;
  text-align: right;
  max-height: 20px;
  word-break: break-word;
  color: var(--primary-font-color);
}
.profile-tenant {
  word-break: break-word;
  align-self: start;
  margin-top: 5px;
  text-align: right;
  max-height: 20px;
  color: var(--primary-font-color);
  overflow: hidden;
}
.profile-img {
  align-self: center;
  justify-self: center;
  width: 70%;
  border-radius: 100px;
}

.languages-btns-container{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  justify-content: center;
}
.language-icon{
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.language-icon:hover{
  background-color: var(--background-color-contrasted);
}
.language-icon.language-icon-selected{
  background-color: var(--primary-color);
}

.header-notification{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  gap: 5px;

  padding: 10px;
  color: var(--primary-font-color);
  transition: all 0.1s ease-in-out;
}
.header-notification:hover{
  background-color: var(--background-color-contrasted-hover);
}
.header-notification-icons{
  display: grid;
  justify-items: center;
  gap: 5px;
}

.user-option{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  justify-items: center;
  gap: 10px;

  border-radius: 10px;
  background-color: var(--background-color-contrasted);
  padding: 10px;
}

.user-option .user-option-title{
  margin: 0;
  color: var(--primary-font-color);
  align-self: center;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  #my-header {
    grid-template-columns: minmax(50px, 80px) minmax(50px, 80px) 1fr;
  }
  .ibisa-tools-container {
    display: none;
  }
  .search-container {
    padding-right: 20px;
  }
  .btn-sidebar{
    display: block;
  }
  .right-ibisa-options{
    width: 100%;
    justify-self: end;
    justify-content: end;
    grid-template-columns: max-content min-content minmax(200px, max-content); 
  }
}

@media screen and (max-width: 640px) {
  #my-header{
    grid-template-columns: 80px minmax(50px, 80px) minmax(200px, 1fr);
  }
  .profile-container{
    grid-template-columns: 80px;
    justify-self: end;
  }
  .btn-sidebar{
    display: block;
  }
  .profile-text{
    display: none;
  }
  .ibisa-tools-container {
    display: none;
  }
  .right-ibisa-options{
    grid-template-columns: max-content 80px minmax(80px, max-content);
  }
}
@media screen and (max-width: 493px) {
  #my-header{
    grid-template-columns: 80px minmax(50px, 80px) minmax(130px, 1fr);
  }
  .right-ibisa-options{
    justify-content: end;
    grid-template-columns: max-content minmax(80px, max-content);
    padding: 0;
  }
  .search-container{
    display: none;
  }
  .ibisa-apps-option{
    display: none;
  }
}
/* Dropdown Button */
.dropbtn {
  color: rgb(22, 21, 21);
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  position: relative;
  background-color: #f5f5f500;
}
.ibisa-apps-text {
  opacity: 1;
  font-weight: bold;
  font-family: "Poppins-Light";
  color: var(--secondary-font-color);
}
.profile-container .dropdown-content{
  right: 7%;
}