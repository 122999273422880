.push-notification-container{
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content;
  align-items: center;
  justify-self: center;
  gap: 10px;

  width: 100%;
  max-width: 600px;
  height: 75px;
  padding: 10px;
  position: absolute;
  z-index: 2000;
  transition: all 0.5s ease-in-out;
  /* transform: translate(50%); */
  background-color: var(--background-color-contrasted);
  border-radius: 10px;
  border: solid 1px var(--primary-border-color);
  color: var(--primary-font-color);
}
.push-notification-container h4, .push-notification-container p{
  margin: 0;
}
.push-notification-container .push-notification-info{
  display: grid;
  grid-template-columns: 1fr max-content;

  overflow: hidden;
  height: 55px;
}
.push-notification-info .push-notification-text, .push-notification-info .push-notification-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.push-notification-info .push-notification-close-btn {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  cursor: pointer;
}
.push-notification-container .push-notification-icon{
  font-size: 30px;
}
.push-notification-container.pushing{
  top: 10px;
}
.push-notification-container.not-pushing{
  top: -75px;
}