#my-main{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-column: 1 / 13;
  grid-row: 2 / 13;

  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--background-color);
}