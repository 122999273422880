:root {
  --background-color: #fff;
  --background-color-alter: #202124;
  --background-color-contrasted-light: #f9f9f9;
  --background-color-contrasted: #f5f5f5;
  --background-color-contrasted-hover: #e4e4e4;
  --background-modeler-color: #fff;
  --primary-color: #ff715a;
  --secondary-color: #fff;
  --primary-border-color: #cecece;
  --secondary-border-color: #d5d5d5;
  --primary-font-color: #000000;
  --secondary-font-color: #585858;
  --terciary-font-color: #737373;
  --black-btn-color: #000000;
  --gray-btn-color: #535353;
  --green-btn-color: #29d98c;
  --red-btn-color: #ff4b4b;
  --blue-btn-color: #056cf2;
  --purple-btn-color: #653bbf;
  --yellow-btn-color: #deb500;
  --orange-btn-color: #ff715a;
  --black-btn-color-hover: #000000;
  --gray-btn-color-hover: #535353;
  --green-btn-color-hover: #29d98c;
  --red-btn-color-hover: #ff4b4b;
  --blue-btn-color-hover: #056cf2;
  --purple-btn-color-hover: #653bbf;
  --yellow-btn-color-hover: #deb500;
  --orange-btn-color-hover: #ff715a;
}

.title{
  font-family: "Poppins-ExtraBold";
  font-weight: bold;
  font-size: 1.6rem;
}

.subtitle{
  font-family: "Poppins-Bold";
  color: #585858;
  font-size: 1.25rem;
}

.title-table{
  font-family: "Poppins-Medium";
  font-size: 1.6rem;
  font-weight: bold;
}

.title-table-light{
  font-family: "Poppins-Light";
  font-size: 1.6rem;
  font-weight: bold;
}
.h-primary{
  font-family: "Poppins-Bold";
  color: var(--primary-color);
}

.paragraph{
  font-family: "Poppins-Light";
  font-size: 1rem;
}

.bold {
  font-family: "Poppins-Bold";
}

.little {
  font-size: 1.2rem;
}

.h1 {
  font-size: 3rem;
}

.h2 {
  font-size: 2.6rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.6rem;
}

.text-info {
  align-self: center;
  justify-self: center;
  text-align: center;
}

.custom-input-search{
  width: 100%;
  max-width: 600px;
  min-width: 250px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--background-color-contrasted);
  padding: 0 10px;
  outline: none;
  border: none;
  font-family: "Poppins-Medium";
  color: var(--primary-font-color);
}
.custom-input-search::placeholder{
  font-family: "Poppins-Medium";
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  color: var(--secondary-font-color);
}

.custom-text-field input[type="date"][value=""] {
  transition: all 0.1s ease-in-out;
  color: transparent;
}
.custom-text-field input[type="date"]:focus{
  color: inherit;
}

.custom-text-field input[type="datetime-local"][value=""] {
  transition: all 0.1s ease-in-out;
  color: transparent;
}
.custom-text-field input[type="datetime-local"]:focus{
  color: inherit;
}
.text-cursive{
  font-style: oblique;
}

.btn{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 5px;

  padding: 5px;
  width: min-content;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Poppins-Medium";
  transition: all 0.1s ease-in-out;
  height: max-content;
  text-decoration: none;
}
.btns-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  height: max-content;
}
.btns-container.btns-center{
  justify-content: center;
  align-items: center;
}
/* config de bototnes */
.btn.btn-small{
  padding: 3px;
  font-size: 1.3rem;
}
.btn.btn-medium{
  padding: 10px;
  font-size: 1.6rem;
}
.btn.btn-big{
  padding: 17px;
  font-size: 1.8rem;
}
.btn.btn-circle{
  border-radius: 1000px;
}
.btn.btn-small .btn-icon{
  font-size: 1.5rem;
}
.btn .btn-icon{
  font-size: 2rem;
}
.btn.btn-big .btn-icon{
  font-size: 2.5rem;
}

/* color de botones */
.btn-black{
  background-color: var(--black-btn-color);
  color: #fff;
  border: solid 1px var(--black-btn-color-hover);
}
.btn-black:hover{
  background-color: transparent;
  color: var(--primary-font-color);
  border: solid 1px var(--primary-font-color);
}
.btn-gray{
  background-color: var(--gray-btn-color);
  color: #fff;
  border: solid 1px var(--gray-btn-color);
}
.btn-gray:hover{
  background-color: transparent;
  color: var(--gray-btn-color-hover);
}
.btn-red{
  background-color: var(--red-btn-color);
  color: #fff;
  border: solid 1px var(--red-btn-color);
}
.btn-red:hover{
  background-color: transparent;
  color: var(--red-btn-color-hover);
}
.btn-green{
  background-color: var(--green-btn-color);
  color: #fff;
  border: solid 1px var(--green-btn-color);
}
.btn-green:hover{
  background-color: transparent;
  color: var(--green-btn-color-hover);
}
.btn-blue{
  background-color: var(--blue-btn-color);
  color: #fff;
  border: solid 1px var(--blue-btn-color);
}
.btn-blue:hover{
  background-color: transparent;
  color: var(--blue-btn-color-hover);
}
.btn-purple{
  background-color: var(--purple-btn-color);
  color: #fff;
  border: solid 1px var(--purple-btn-color);
}
.btn-purple:hover{
  background-color: transparent;
  border: solid 1px var(--purple-btn-color-hover);
  color: var(--purple-btn-color-hover);
}
.btn-yellow{
  background-color: var(--yellow-btn-color);
  color: #fff;
  border: solid 1px var(--yellow-btn-color);
}
.btn-yellow:hover{
  background-color: transparent;
  border: solid 1px var(--yellow-btn-color-hover);
  color: var(--yellow-btn-color-hover);
}
.btn-orange{
  background-color: var(--orange-btn-color);
  color: #fff;
  border: solid 1px var(--orange-btn-color);
}
.btn-orange:hover{
  background-color: transparent;
  border: solid 1px var(--orange-btn-color-hover);
  color: var(--orange-btn-color-hover);
}

.btn.btn-transparent{
  background-color: transparent;
  border: none;
}
.btn.btn-transparent:hover{
  background-color: var(--background-color-contrasted);
}
.btn.btn-transparent.btn-black{
  color: var(--primary-font-color);
}
.btn.btn-transparent.btn-black:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-gray{
  color: var(--terciary-font-color);
}
.btn.btn-transparent.btn-gray:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-red{
  color: var(--red-btn-color);
}
.btn.btn-transparent.btn-red:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-green{
  color: var(--green-btn-color);
}
.btn.btn-transparent.btn-green:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-blue{
  color: var(--blue-btn-color);
}
.btn.btn-transparent.btn-blue:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-purple{
  color: var(--purple-btn-color-hover);
}
.btn.btn-transparent.btn-purple:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-yellow{
  color: var(--yellow-btn-color);
}
.btn.btn-transparent.btn-yellow:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-transparent.btn-orange{
  color: var(--orange-btn-color);
}
.btn.btn-transparent.btn-orange:hover{
  background-color: var(--background-color-contrasted-hover);
}

.btn.btn-inverted.btn-black{
  background-color: transparent;
  color: var(--black-btn-color);
  border: none;
}
.btn.btn-inverted.btn-black:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-gray{
  background-color: transparent;
  color: var(--gray-btn-color);
  border: none;
}
.btn.btn-inverted.btn-gray:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-red{
  background-color: transparent;
  color: var(--red-btn-color);
  border: none;
}
.btn.btn-inverted.btn-red:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-green{
  background-color: transparent;
  color: var(--green-btn-color);
  border: none;
}
.btn.btn-inverted.btn-green:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-blue{
  background-color: transparent;
  color: var(--blue-btn-color);
  border: none;
}
.btn.btn-inverted.btn-blue:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-purple{
  background-color: transparent;
  color: var(--purple-btn-color-hover);
  border: none;
}
.btn.btn-inverted.btn-purple:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-yellow{
  background-color: transparent;
  color: var(--yellow-btn-color);
  border: none;
}
.btn.btn-inverted.btn-yellow:hover{
  background-color: var(--background-color-contrasted-hover);
}
.btn.btn-inverted.btn-orange{
  background-color: transparent;
  color: var(--orange-btn-color);
  border: none;
}
.btn.btn-inverted.btn-orange:hover{
  background-color: var(--background-color-contrasted-hover);
}

.btn[disabled]{
  border: solid 1px #777777;
  background-color: transparent;
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}
.btn[disabled]:hover{
  background-color: transparent;
  color: rgb(119, 119, 119);
}

.btn[disabled].btn-transparent{
  border: none;
  background-color: transparent;
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}
.btn[disabled].btn-transparent:hover{
  border: none;
  background-color: transparent;
  color: rgb(119, 119, 119);
}

.btns-gapper{
  display: flex;
  gap: 5px;
}



.custom-text-field *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
}
.custom-text-field .MuiFormHelperText-root{
  font-size: 1rem !important;
}

.custom-pagination *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
  color: var(--primary-font-color);
}
.custom-pagination .MuiFormHelperText-root{
  font-size: 1rem !important;
}

.custom-select *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
}
.custom-select .MuiFormHelperText-root{
  font-size: 1rem !important;
}

.custom-autocomplete *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
}
.custom-autocomplete .MuiFormHelperText-root{
  font-size: 1rem !important;
}

*.text-tip{
  font-size: 1.2rem;
  font-style: oblique;
  color: var(--secondary-font-color);
  margin: 0;
}
*.MuiTablePagination-toolbar, *.MuiTablePagination-toolbar * {
  font-size: 1.4rem !important;
}

*.MuiFormControl-root, *.MuiFormControl-root *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
}
*.MuiFormControl-root input[type=datetime-local][value=""], *.MuiFormControl-root input[type=datetime-local][value=""] {
  color: transparent;
  transition: all .1s ease-in-out;

  &:focus {
    color: inherit;
    transition: all .1s ease-in-out;
  }

  &::after {
    color: var(--primary-font-color);
  }
}
*.MuiFormHelperText-root, *.MuiFormHelperText-root *{
  font-size: 1rem !important;
}
*.MuiAutocomplete-popper, *.MuiAutocomplete-popper *, .MuiPopper-root, .MuiPopper-root * {
  font-size: 1rem !important;
}
.MuiAutocomplete-option {
  font-size: 1rem !important;
}
.custom-pagination *{
  font-size: 1.6rem !important;
  font-family: "Poppins-Regular" !important;
  color: var(--primary-font-color);
}
*.MuiTablePagination-toolbar, *.MuiTablePagination-toolbar * {
  font-size: 1.4rem !important;
}