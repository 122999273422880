.ibisa-button {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
  width: min-content;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Poppins-Medium";
  transition: all 0.1s ease-in-out;
  height: max-content;
  text-decoration: none;
  /* config de bototnes */
  /* color de botones */
}
.ibisa-button.btn-small {
  padding: 3px;
  font-size: 1.3rem;
}
.ibisa-button.btn-medium {
  padding: 10px;
  font-size: 1.6rem;
}
.ibisa-button.btn-big {
  padding: 17px;
  font-size: 1.8rem;
}
.ibisa-button.btn-circle {
  border-radius: 1000px;
}
.ibisa-button.btn-small .btn-icon {
  font-size: 1.5rem;
}
.ibisa-button .btn .btn-icon {
  font-size: 2rem;
}
.ibisa-button.btn-big .btn-icon {
  font-size: 2.5rem;
}
.ibisa-button.btn-black {
  background-color: var(--black-btn-color);
  color: #fff;
  border: solid 1px var(--black-btn-color-hover);
}
.ibisa-button.btn-black:hover {
  background-color: transparent;
  color: var(--primary-font-color);
  border: solid 1px var(--primary-font-color);
}
.ibisa-button.btn-gray {
  background-color: var(--gray-btn-color);
  color: #fff;
  border: solid 1px var(--gray-btn-color);
}
.ibisa-button.btn-gray:hover {
  background-color: transparent;
  color: var(--gray-btn-color-hover);
}
.ibisa-button.btn-red {
  background-color: var(--red-btn-color);
  color: #fff;
  border: solid 1px var(--red-btn-color);
}
.ibisa-button.btn-red:hover {
  background-color: transparent;
  color: var(--red-btn-color-hover);
}
.ibisa-button.btn-green {
  background-color: var(--green-btn-color);
  color: #fff;
  border: solid 1px var(--green-btn-color);
}
.ibisa-button.btn-green:hover {
  background-color: transparent;
  color: var(--green-btn-color-hover);
}
.ibisa-button.btn-blue {
  background-color: var(--blue-btn-color);
  color: #fff;
  border: solid 1px var(--blue-btn-color);
}
.ibisa-button.btn-blue:hover {
  background-color: transparent;
  color: var(--blue-btn-color-hover);
}
.ibisa-button.btn-purple {
  background-color: var(--purple-btn-color);
  color: #fff;
  border: solid 1px var(--purple-btn-color);
}
.ibisa-button.btn-purple:hover {
  background-color: transparent;
  border: solid 1px var(--purple-btn-color-hover);
  color: var(--purple-btn-color-hover);
}
.ibisa-button.btn-yellow {
  background-color: var(--yellow-btn-color);
  color: #fff;
  border: solid 1px var(--yellow-btn-color);
}
.ibisa-button.btn-yellow:hover {
  background-color: transparent;
  border: solid 1px var(--yellow-btn-color-hover);
  color: var(--yellow-btn-color-hover);
}
.ibisa-button.btn-orange {
  background-color: var(--orange-btn-color);
  color: #fff;
  border: solid 1px var(--orange-btn-color);
}
.ibisa-button.btn-orange:hover {
  background-color: transparent;
  border: solid 1px var(--orange-btn-color-hover);
  color: var(--orange-btn-color-hover);
}
.ibisa-button.btn-transparent {
  background-color: transparent;
  border: none;
}
.ibisa-button.btn-transparent:hover {
  background-color: var(--background-color-contrasted);
}
.ibisa-button.btn-transparent.btn-black {
  color: var(--primary-font-color);
}
.ibisa-button.btn-transparent.btn-black:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-gray {
  color: var(--terciary-font-color);
}
.ibisa-button.btn-transparent.btn-gray:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-red {
  color: var(--red-btn-color);
}
.ibisa-button.btn-transparent.btn-red:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-green {
  color: var(--green-btn-color);
}
.ibisa-button.btn-transparent.btn-green:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-blue {
  color: var(--blue-btn-color);
}
.ibisa-button.btn-transparent.btn-blue:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-purple {
  color: var(--purple-btn-color-hover);
}
.ibisa-button.btn-transparent.btn-purple:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-yellow {
  color: var(--yellow-btn-color);
}
.ibisa-button.btn-transparent.btn-yellow:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-transparent.btn-orange {
  color: var(--orange-btn-color);
}
.ibisa-button.btn-transparent.btn-orange:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-black {
  background-color: transparent;
  color: var(--black-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-black:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-gray {
  background-color: transparent;
  color: var(--gray-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-gray:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-red {
  background-color: transparent;
  color: var(--red-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-red:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-green {
  background-color: transparent;
  color: var(--green-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-green:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-blue {
  background-color: transparent;
  color: var(--blue-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-blue:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-purple {
  background-color: transparent;
  color: var(--purple-btn-color-hover);
  border: none;
}
.ibisa-button.btn-inverted.btn-purple:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-yellow {
  background-color: transparent;
  color: var(--yellow-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-yellow:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button.btn-inverted.btn-orange {
  background-color: transparent;
  color: var(--orange-btn-color);
  border: none;
}
.ibisa-button.btn-inverted.btn-orange:hover {
  background-color: var(--background-color-contrasted-hover);
}
.ibisa-button[disabled] {
  border: solid 1px #777777;
  background-color: transparent;
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}
.ibisa-button[disabled]:hover {
  background-color: transparent;
  color: rgb(119, 119, 119);
}
.ibisa-button[disabled].btn-transparent {
  border: none;
  background-color: transparent;
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}
.ibisa-button[disabled].btn-transparent:hover {
  border: none;
  background-color: transparent;
  color: rgb(119, 119, 119);
}

.btns-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  height: max-content;
}

.btns-container.btns-center {
  justify-content: center;
  align-items: center;
}

.btns-gapper {
  display: flex;
  gap: 5px;
}